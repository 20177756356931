@font-face {
	font-family: 'biome';
	src: url('../../public/assets/fonts/biome.ttf') format('truetype');
}
@font-face {
	font-family: 'cheveuxdange';
	src: url('../../public/assets/fonts/cheveuxdange.ttf') format('truetype');
}
@font-face {
	font-family: 'computer';
	src: url('../../public/assets/fonts/computer_pixel.ttf') format('truetype');
}
@font-face {
	font-family: 'eurostyle';
	src: url('../../public/assets/fonts/eurostyle.ttf') format('truetype');
}
@font-face {
	font-family: 'eurostyle-bold';
	src: url('../../public/assets/fonts/eurostyle_b.ttf') format('truetype');
}
@font-face {
	font-family: 'avenger';
	src: url('../../public/assets/fonts/avenger.ttf') format('truetype');
}
@font-face {
	font-family: 'souses';
	src: url('../../public/assets/fonts/souses.otf') format('opentype');
}
