@import './colors.scss';

.footer {
	border-top: 1px solid $light-gray;
	background-color: white;
	color: black;
	li {
		text-decoration: none;
		list-style-type: none;
		text-transform: capitalize;
	}
	.scholarcade-logo {
		max-width: 200px;
	}
	.footer-bottom-text {
		color: #6c757d;
		font-size: 12px;
		p {
			margin: 0;
		}
	}
}
