@import './colors.scss';
@import './bootstrap-override.scss';
@import './fonts.scss';

html,
body {
	overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 300;
	color: white;
}

.section {
	width: 100%;
	position: relative;
	vertical-align: top;
}

h1,
h2,
h3,
h5,
h6 {
	font-family: 'eurostyle';
}
.avenger {
	font-family: 'avenger';
}

.smoke-background {
	background-image: url('../../public/assets/images/smoke.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
	min-height: 900px;
}

a {
	text-decoration: none;
	color: inherit;
	cursor: pointer;
	&.light {
		&:hover {
			color: #c3bfff;
		}
	}
	&.dark {
		&:hover {
			color: #423c90;
		}
	}
}

.circle-container {
	z-index: 10;
	text-align: center;
	h5 {
		margin-top: 20px;
	}
	.circle {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		margin: 0 auto;
		span {
			margin-top: 10px;
			display: inline-block;
			font-weight: bold;
		}
		&.red {
			background-color: $red;
		}
	}
}

.center-text {
	text-align: center;
}

.heading-container {
	font-family: Open Sans, sans-serif;
	font-weight: 300;
	letter-spacing: 0.1rem;
	width: 100%;
	text-align: center;
	img {
		width: 75%;
		max-width: 800px;
		// margin: 0 auto;
	}
}

.overlay {
	position: absolute;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	display: flex; /* establish flex container */
	flex-direction: column; /* make main axis vertical */
	justify-content: center; /* center items vertically, in this case */
	align-items: center; /* center items horizontally, in this case */
	height: inherit;
	background: rgba(0, 0, 0, 0.8);
	z-index: 90;
	color: white;
	&.no-dark {
		background: none;
	}
	&.no-events {
		pointer-events: none;
	}
	&.gradient {
		background: none;
		background-image: linear-gradient(to right, #191818, rgba(0, 0, 0, 0) 30%);
	}
}

.btn {
	color: white;
	transition: border 0.5s, width 0.5s, height 0.5s, background-color 0.5s, color 0.5s, transform 0.5s;
	&.w-lg {
		width: 200px;
	}
	&.white-border {
		border: 1px solid white;
		background: none;
		color: white;
		&:hover {
			background: white;
			color: black;
		}
	}
	&.dark-border {
		border: 1px solid $darker-gray;
		background: none;
		color: black;
		&:hover {
			background: $darker-gray;
			color: white;
		}
	}
}

hr.style-one {
	border: 0;
	height: 1px;
	background: #333;
	background-image: linear-gradient(to right, #333, #ccc, #333);
}

hr.style-two {
	border: 0;
	height: 1px;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

hr.style-four {
	height: 12px;
	border: 0;
	box-shadow: inset 0 12px 12px -12px rgba(0, 0, 0, 0.5);
}

hr.style-six {
	border: 0;
	height: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.clickable {
	cursor: pointer;
	transition: color 0.5s, transform 0.5s;
	text-shadow: 0 1px 6px rgba(0, 0, 0, 0.8);
	&:hover {
		text-decoration: underline;
	}
	&.active {
		text-decoration: underline;
	}
	&.light {
		&:hover {
			color: black;
		}
	}
	&.dark {
		&:hover {
			color: white;
		}
	}
}
