.bg-light {
	background-color: #efefef !important;
	color: black;
}
.bg-dark {
	color: white;
}
.bg-header-blue {
	color: white;
	background-color: $header-blue;
}
.bg-red-gradient {
	background-color: #4c4c4c;
	background-image: linear-gradient(90deg, rgba(57, 194, 218, 0), #f000275e 35%, rgba(57, 194, 218, 0));
	color: white;
}
.bg-dark-gradient {
	background-image: radial-gradient(#474548, #2a282b);
	color: white;
}
.bg-dark-blue {
	background-color: $main-dark-blue;
}
.bg-gray {
	background-color: #3e3e3e;
	color: white;
}

.card {
	text-align: center;
	background-color: #efefef;
	border: 2px solid $main-blue;
	img {
		width: 100%;
		margin: 0 auto;
	}
	.card-body {
		color: black;
	}
	&.min-height-lg {
		min-height: 360px;
	}
	&.min-height-md {
		min-height: 300px;
	}
	&.min-height-sm {
		min-height: 120px;
	}
}
