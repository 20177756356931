@import './colors.scss';

.navbar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 60px;
	background-color: #023e5e;
	background-image: radial-gradient(#062831, #062b35);
	box-shadow: 0 4px 2px -2px rgb(0 0 0 / 20%);
	.navbar-nav {
		margin-left: auto;
		text-transform: capitalize;
	}
	img {
		width: 150px;
	}
	a {
		&.active {
			color: #a09dcb;
		}
	}
}

.heading-spacer {
	margin-top: 50px;
	width: 100%;
	display: inline-block;
}
