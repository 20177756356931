/* Main theme colors */

$red: #f00027;

$light-gray: #dddddd;
$medium-gray: #696571;
$dark-gray: #37353b;
$darker-gray: #2a282b;
$border-gray: #eaeaea;
$form-gray: #f2f2f2;
$white: #ffffff;

$footer-text: #696571;

$main-blue: #023e5e;
$main-dark-blue: #04171c;
$header-blue: #062a33;
